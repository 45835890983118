var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import style from "./UserFromDenendsOnRole/formStyles.module.css";
import { useAppDispatch } from "../../../../redux/store";
import { setPasswordToUpdate, setPasswordsFiled } from "../../../../redux/updateUserReducer";
import { useFormik } from "formik";
import { PwdInputForUserSettingsModal } from "../../../Helpers/Forms/InputForUserSettingsModal/PwdInputForUserSettingsModal";
import { passwordsSchema } from "../../../../schemas/userSettingsSchema/userSettingsSchema";
import { useSelector } from "react-redux";
export var ChangeUserPassword = function () {
    var dispatch = useAppDispatch();
    var isSocial = useSelector(function (state) { return state.auth.userAuthInfo.social; });
    useEffect(function () {
        var data = {
            password: {
                old_password: "",
                new_password: "",
            },
        };
        dispatch(setPasswordToUpdate(data));
    }, []);
    var initialValuePwd = {};
    var _a = useFormik({
        initialValues: initialValuePwd,
        enableReinitialize: true,
        validationSchema: passwordsSchema,
        onSubmit: function (values, formikHelpers) { },
    }), values = _a.values, errors = _a.errors, touched = _a.touched, handleBlur = _a.handleBlur, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, isValid = _a.isValid;
    // * ---------------- CHANGERS ----------------------- * //
    var onChangeOldPassword = function (value) {
        dispatch(setPasswordsFiled({ field: "old_password", value: value }));
    };
    var onChangeNewPassword1 = function (value) {
        dispatch(setPasswordsFiled({ field: "new_password", value: value }));
    };
    var onChangeNewPassword2 = function (value) {
        dispatch(setPasswordsFiled({ field: "new_password_repeat", value: value }));
    };
    // * ---------------- CHANGERS ----------------------- * //
    return (_jsxs("div", __assign({ className: style.formPasswordWrapper }, { children: [!isSocial ? (_jsx(PwdInputForUserSettingsModal, { handleDispatchValue: onChangeOldPassword, handleChange: handleChange, handleBlur: handleBlur, isError: errors.oldPass && touched.oldPass, value: values.oldPass, placeholder: "Старый пароль", type: "password", name: "oldPass" })) : null, _jsx(PwdInputForUserSettingsModal, { handleDispatchValue: onChangeNewPassword1, handleChange: handleChange, handleBlur: handleBlur, isError: errors.newPassword1 && touched.newPassword1, value: values.newPassword1, placeholder: "Новый пароль", type: "password", name: "newPassword1" }), _jsx(PwdInputForUserSettingsModal, { handleDispatchValue: onChangeNewPassword2, handleChange: handleChange, handleBlur: handleBlur, isError: errors.newPassword2 && touched.newPassword2, value: values.newPassword2, placeholder: "Новый пароль еще раз", type: "password", name: "newPassword2" })] })));
};
