// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A1X9iH9xT_pLSSCkD__2 {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 354px;
  height: 204px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 1px #000;
  opacity: 1;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.GGN4AOj3PiBuS5v1j4V_ {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.dDSFRPuu7ZluIFx6k2W4 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.OU05SDFQ33Vi31xi9AOA {
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: -23px;
  color: black;
}

.ilN8J9kHGv_mrsa8y2nC {
  width: 16px;
  margin: 6px;
  height: 16px;
  cursor: pointer;
}

.pm5D4RFnoIWnps_BEUeM {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalResetPassword/modalResetPassword.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wBAAwB;EACxB,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".reset_password_block {\r\n  position: fixed;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 354px;\r\n  height: 204px;\r\n  background-color: #fff;\r\n  border-radius: 12px;\r\n  box-shadow: 0 0 1px #000;\r\n  opacity: 1;\r\n  z-index: 100;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n.reset_password_input {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-evenly;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.close_modal {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.titleStyle {\r\n  font-size: 20px;\r\n  padding-bottom: 10px;\r\n  margin-top: -23px;\r\n  color: black;\r\n}\r\n\r\n.visibleIcon {\r\n  width: 16px;\r\n  margin: 6px;\r\n  height: 16px;\r\n  cursor: pointer;\r\n}\r\n\r\n.button_reset_password_enter {\r\n  padding-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset_password_block": `A1X9iH9xT_pLSSCkD__2`,
	"reset_password_input": `GGN4AOj3PiBuS5v1j4V_`,
	"close_modal": `dDSFRPuu7ZluIFx6k2W4`,
	"titleStyle": `OU05SDFQ33Vi31xi9AOA`,
	"visibleIcon": `ilN8J9kHGv_mrsa8y2nC`,
	"button_reset_password_enter": `pm5D4RFnoIWnps_BEUeM`
};
export default ___CSS_LOADER_EXPORT___;
