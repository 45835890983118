var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../../redux/store";
import style from "./formStyles.module.css";
import { useSelector } from "react-redux";
import { setDataToUpdate, setUserUpdateField } from "../../../../../redux/updateUserReducer";
export var CompanyForm = function () {
    var _a, _b, _c, _d, _e;
    var company = useSelector(function (state) { return state.auth.userAuthInfo; });
    var userData = useSelector(function (state) { var _a; return (_a = state.updateUser) === null || _a === void 0 ? void 0 : _a.dataToUpdate; });
    var isUpdatingFormActivated = useSelector(function (state) { return state.updateUser.isUpdatingFormActivated; });
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (!isUpdatingFormActivated) {
            var currentData = {
                about: {
                    info: {
                        name: (_b = (_a = company === null || company === void 0 ? void 0 : company.info) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "",
                        status: (_d = (_c = company === null || company === void 0 ? void 0 : company.info) === null || _c === void 0 ? void 0 : _c.status) !== null && _d !== void 0 ? _d : "",
                    },
                    email: (_e = company === null || company === void 0 ? void 0 : company.email) !== null && _e !== void 0 ? _e : "",
                },
            };
            dispatch(setDataToUpdate(currentData));
        }
    }, []);
    var dispatch = useAppDispatch();
    // * ---------------- CHANGERS ----------------------- * //
    var onChangeUserName = function (value) {
        dispatch(setUserUpdateField({ field: "email", value: value }));
    };
    var onChangeName = function (value) {
        dispatch(setUserUpdateField({ field: "name", value: value }));
    };
    var onChangeStatus = function (value) {
        dispatch(setUserUpdateField({ field: "status", value: value }));
    };
    var onKeyDown = function (event) {
        // if (event.code === "Space") event.preventDefault();
    };
    // * ---------------- CHANGERS ----------------------- * //
    return (_jsxs("div", __assign({ className: style.formWrapper }, { children: [_jsxs("div", __assign({ className: style.labelAndInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "E-mail" })), _jsx("input", { id: "email", value: (_a = userData === null || userData === void 0 ? void 0 : userData.about) === null || _a === void 0 ? void 0 : _a.email, onChange: function (e) { return onChangeUserName(e.target.value); }, className: style.inputNotActive, readOnly: true })] })), _jsxs("div", __assign({ className: style.labelAndInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: "name" }, { children: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" })), _jsx("input", { className: style.inputInfoForm, value: (_c = (_b = userData === null || userData === void 0 ? void 0 : userData.about) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c.name, onKeyDown: function (e) { return onKeyDown(e); }, onChange: function (e) { return onChangeName(e.target.value); } })] })), _jsxs("div", __assign({ className: style.labelAndInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: "status" }, { children: "\u0421\u0442\u0430\u0442\u0443\u0441 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" })), _jsx("span", { children: "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u043E\u0435 \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432 \u0441 \u043F\u0440\u043E\u0431\u0435\u043B\u0430\u043C\u0438: 53" }), _jsx("input", { maxLength: 53, className: style.inputInfoForm, value: (_e = (_d = userData === null || userData === void 0 ? void 0 : userData.about) === null || _d === void 0 ? void 0 : _d.info) === null || _e === void 0 ? void 0 : _e.status, onKeyDown: function (e) { return onKeyDown(e); }, onChange: function (e) { return onChangeStatus(e.target.value); } })] }))] })));
};
