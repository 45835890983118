// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tCEqjLQfa2lWEC1NXwl5 {
    border-bottom: 1px solid var(--main-Gray-4-color);
    display: flex;
    height: 30px;
    justify-content: start;
    margin-bottom: 22px;
    width: 100%;

}

.Il1YT5y7nKKusy2IdqGb,
.Dbx4TDmKgB4WEcKyZ1t_ {
    display: flex;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;
    height: 30px;
}

.Il1YT5y7nKKusy2IdqGb:not(:last-child),
.Dbx4TDmKgB4WEcKyZ1t_:not(:last-child) {
    margin-right: 28px;
}

.Il1YT5y7nKKusy2IdqGb {
    color: var(--main-Gray-3-color);
}

.Dbx4TDmKgB4WEcKyZ1t_ {
    border-bottom: 3px solid var(--main-Logo-color);
    color: var(--main-Logo-color);
}

@media (max-width: 670px) {
    .tCEqjLQfa2lWEC1NXwl5 {
        position: relative;
    }

    .VVkwJMXz1p4_XMuJyh4O {
        height: 37px;
        display: flex;
        overflow-x: auto;
        padding: 0;
        margin: 0;
        width: 98%;
    }

    .VVkwJMXz1p4_XMuJyh4O::-webkit-scrollbar {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalSettingAndUpdateUser/UserEditTogglers/userEditTogglers.module.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;;AAEf;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+CAA+C;IAC/C,6BAA6B;AACjC;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,YAAY;QACZ,aAAa;QACb,gBAAgB;QAChB,UAAU;QACV,SAAS;QACT,UAAU;IACd;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".togglerWrapper {\r\n    border-bottom: 1px solid var(--main-Gray-4-color);\r\n    display: flex;\r\n    height: 30px;\r\n    justify-content: start;\r\n    margin-bottom: 22px;\r\n    width: 100%;\r\n\r\n}\r\n\r\n.nav,\r\n.nav_active {\r\n    display: flex;\r\n    border: none;\r\n    background-color: transparent;\r\n    font-size: 16px;\r\n    font-weight: 400;\r\n    cursor: pointer;\r\n    white-space: nowrap;\r\n    height: 30px;\r\n}\r\n\r\n.nav:not(:last-child),\r\n.nav_active:not(:last-child) {\r\n    margin-right: 28px;\r\n}\r\n\r\n.nav {\r\n    color: var(--main-Gray-3-color);\r\n}\r\n\r\n.nav_active {\r\n    border-bottom: 3px solid var(--main-Logo-color);\r\n    color: var(--main-Logo-color);\r\n}\r\n\r\n@media (max-width: 670px) {\r\n    .togglerWrapper {\r\n        position: relative;\r\n    }\r\n\r\n    .scrollableList {\r\n        height: 37px;\r\n        display: flex;\r\n        overflow-x: auto;\r\n        padding: 0;\r\n        margin: 0;\r\n        width: 98%;\r\n    }\r\n\r\n    .scrollableList::-webkit-scrollbar {\r\n        display: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"togglerWrapper": `tCEqjLQfa2lWEC1NXwl5`,
	"nav": `Il1YT5y7nKKusy2IdqGb`,
	"nav_active": `Dbx4TDmKgB4WEcKyZ1t_`,
	"scrollableList": `VVkwJMXz1p4_XMuJyh4O`
};
export default ___CSS_LOADER_EXPORT___;
